import React, { Component } from "react";
import { Button, Grid, Header, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import KaisakuApi from "../controllers/kaisaku-api";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import { AdAregionSwipetoslide } from "../components/react-slick/ad-aregion-swipetoslide";
import { AdBcdregionSwipetoslide } from "../components/react-slick/ad-bcdregion-swipetoslide";
import "../styles/bg.css";
import cookie from "react-cookies";
import zdlbg from "../images/main/zdlbg.png";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId: '',
            gameId: '',
            eventId: '',
            accessToken: '',
            nickname: '',
            createdAt: '',
            expireAt: '',
            expireAt_TTL: '',
            divStyle: '',
            adItem: {
                success: false,
                adId: '',
                eventId: '',
                adItem1: {
                    adItems: []
                },
                adItem2: {
                    adItems: []
                },
                adItem3: {
                    adItems: []
                },
                adItem4: {
                    adItems: []
                },
                adImage: '',
                adUrl: '',
                adAImage: '',
                adAUrl: '',
                adBImage: '',
                adBUrl: '',
                adCImage: '',
                adCUrl: ''
            }
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: ''
        };
        let { eventId, userId, gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL } = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        console.log(this.props.location.search);
        console.log("123");
        console.log(eventId);
        // rejectIfAnyNull(this.props.session, this.props.session);
        this.state.expireTimer = setTimeout(() => {
            clearInterval(this.state.expireTimer);
            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            let platform = cookie.load("_platform");
            if ((issdkorapp) || (platform === "h5")) {
                AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                console.log("到时间了,直接进入游戏");
            } else {
                window.close();
            }
        }, 3000);
    }

    async showAd(sType, Url) {
        if (Url !== "") {
            const url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            const newurl = `/gotoad/?eventId=${this.state.adItem.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}&expireAt_TTL=${this.state.expireAt_TTL}&uuid=${uuid}&r1=download`;
            console.log(newurl);
            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            if (issdkorapp) {
                window.location.href = newurl;
            } else {
                window.open(url);
            }
            // setAdItem(this.state.userId, this.state.adItem.eventId, sType);
        }
    }

    async closeAd() {
        if (this.state.expireTimer) {
            clearInterval(this.state.expireTimer);
            this.state.expireTimer = undefined;
            console.log("取消定时器");
        }
        // setAdItem(this.state.userId,this.state.adItem.eventId,"");
    }

    async componentDidMount() {
        if (this.state.adItem.success === false) {
            let getdate = false;
            if (this.state.eventId !== "") {
                this.state.adItem = await getAdItemByEventId(this.state.eventId);
                getdate = this.state.adItem.success;
            }
            if (getdate === false) {
                this.state.adItem = await getAdItem(this.state.userId, 0, 0, this.state.gameId);
            }
            this.setState({
                adItem: this.state.adItem
            });
        }
        let issdkorapp = false;
        issdkorapp = getUA.includes("JGGSDKWebView");
        if (this.state.adItem.success === false) {
            let platform = cookie.load("_platform");
            if ((issdkorapp) || (platform === "h5")) {
                AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
            } else {
                window.close();
            }
        } else {
            if (this.state.expireTimer) {
                clearInterval(this.state.expireTimer);
                this.state.expireTimer = undefined;
                console.log("取消定时器");
            }
            this.setState({
                divStyle: {
                    width: '289px',
                    borderRadius: '10px 10px 0px 0px',
                    display: ''
                }
            });
        }
    }


    render() {
        console.log("5555");
        console.log(this.state.adItem);
		this.closeAd();
        navigate(`/unionpay-page1/${this.props.location.search}&re=3`);
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Modal
                        style={{ width: '289px', borderRadius: '10px 10px 10px 10px' }}
                        open
                    >
                        <div style={this.state.divStyle}>
                            <div style={{
                                height: '45px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                                paddingTop: '12px', border: '0px',
                                borderRadius: '0px 0px 10px 10px',
                                backgroundImage: `url(${zdlbg})`, backgroundSize: '100% 100%',
                                marginTop: "-3px",
                                marginLeft: "-8px",
                                marginRight: "-8px",
                                marginBottom: "-10px",
                                color: "#FFF",
                                opacity: '1'
                            }}
                                onClick={async () => {
                                    this.closeAd();
                                    navigate(`/unionpay-page1/${this.props.location.search}&re=3`);
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    // const res = await KaisakuApi.getNoodleInfo(this.state.gameId, this.state.accessToken);
                                    // if ((res.data.success) || ((res.data.loginData) && (res.data.loginData.showInfo))) {
                                    //     if (res.data.success) {
                                    //         if (res.data.cardtype) {
                                    //             res.data.index = res.data.cardtype;
                                    //         }
                                    //         navigate(`/sdk-ad-noodle-entr/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     } else {
                                    //         navigate(`/sdk-ad-noodle-entronly/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     }
                                    //     // if (res.data.url){
                                    //     //     if (res.data.cardtype){
                                    //     //         res.data.index = res.data.cardtype;
                                    //     //     }
                                    //     //     navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     // }else{
                                    //     //     navigate(`/sdk-ad-noodle/?startTime=${res.data.startTime}&endTime=${res.data.endTime}&attributionId=${res.data.attributionId}&transactionId=${res.data.transactionId}&eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}`);
                                    //     // }
                                    // } else {
                                    //     let issdkorapp = false;
                                    //     issdkorapp = getUA.includes("JGGSDKWebView");
                                    //     let platform = cookie.load("_platform");
                                    //     if ((issdkorapp) || (platform === "h5")) {
                                    //         this.closeAd();
                                    //         AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                    //     } else {
                                    //         this.closeAd();
                                    //         this.setState({
                                    //             divStyle: {
                                    //                 width: '289px',
                                    //                 borderRadius: '10px 10px 0px 0px',
                                    //                 display: 'none'
                                    //             }
                                    //         });
                                    //     }
                                    // }
                                }}
                            >
                                <span style={{ color: '#FFF' }}>請稍後,加載中...~</span>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});
